//https://github.com/orestbida/cookieconsent#installation--usage
window.addEventListener("load", function () {
  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: "cs",
    autoclear_cookies: true, // default: false
    theme_css:
      "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.7.0/dist/cookieconsent.css",
    page_scripts: true, // default: false

    // delay: 0,                               // default: 0
    // auto_language: false,                   // default: false
    // autorun: true,                          // default: true
    force_consent: false, // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    gui_options: {
      consent_modal: {
        layout: "bar", // box/cloud/bar
        position: "bottom center", // bottom/middle/top + left/right/center
        transition: "slide", // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: "box", // box/bar
        // position: 'left',           // left/right
        transition: "slide", // zoom/slide
      },
    },

    onAccept: function (cookie) {
      var storages = {};

      if (cookie.level.includes("targeting")) {
        storages.ad_storage = "granted";
      }
      if (cookie.level.includes("analytics")) {
        storages.analytics_storage = "granted";
      }

      /*gtag("consent", "update", storages);
      dataLayer.push({
        event: "consent_state_update"
      });*/
    },

    onChange: function (cookie, changed_preferences) {
      //
    },

    languages: {
      cs: {
        consent_modal: {
          title: "Používáme cookies",
          description:
            'Tento web používá soubory cookies 🍪. Používáním tohoto webu souhlasíte s ukládáním a používáním nezbytných souborů cookies. Zakliknutím tlačítka "Přijmout vše" udělujete souhlas k ukládání a používání i dalších souborů cookies jako jsou statistické, preferenční a marketingové. <button type="button" data-cc="c-settings" class="cc-link">Nastavení</button>',
          primary_btn: {
            text: "Přijmout vše",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "Odmítnout",
            role: "accept_necessary", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Cookies nastavení",
          save_settings_btn: "Uložit nastavení",
          accept_all_btn: "Přijmout vše",
          reject_all_btn: "Odmítnout vše",
          close_btn_label: "Zavřít",
          cookie_table_headers: [
            {
              col1: "Jméno",
            },
            {
              col2: "Doména",
            },
            {
              col3: "Expirace",
            },
            {
              col4: "Popis",
            },
          ],
          blocks: [
            {
              title: "Využití cookies",
              description:
                'Soubory cookie používáme k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit, kdykoli budete chtít. Více informací o cookies <a class="cc-link" target="_blank" href="https://www.pocernice.cz/urad/povinne-informace-2/cookies/">naleznete zde</a>',
            },
            {
              title: "Nezbytné cookies",
              description:
                "Tyto soubory cookie jsou nezbytné pro správné fungování webových stránek. Bez těchto cookies by web nefungoval správně. Nelze je tedy deaktivovat.",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: "Analytické cookies",
              description:
                "Analytické cookies nám umožňují měření výkonu našeho webu a našich reklamních kampaní. Jejich pomocí určujeme počet návštěv a zdroje návštěv našich internetových stránek. Data získaná pomocí těchto cookies zpracováváme souhrnně, bez použití identifikátorů, které ukazují na konkrétní uživatelé našeho webu. Pokud vypnete používání analytických cookies ve vztahu k Vaší návštěvě, ztrácíme možnost analýzy výkonu a optimalizace našich opatření.",
              toggle: {
                value: "analytics",
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "_ga",
                  col2: ".pocernice.cz",
                  col3: "2 roky",
                  col4: "Používá se k rozlišení uživatelů.",
                },
                {
                  col1: "_gid",
                  col2: ".pocernice.cz",
                  col3: "24 hodin",
                  col4: "Používá se k rozlišení uživatelů.",
                },
              ],
            },
            {
              title: "Marketingové cookies",
              description:
                "Marketingové cookies se využívají k lepšímu cílení reklam (například remarketing, tedy zpětné oslovení uživatele, který už na našem webu byl, na jiném kanálu prostřednictvím placené reklamy) a k jejich personalizaci. Na základě těchto informací není zpravidla možná bezprostřední identifikace Vaší osoby, protože jsou používány pouze pseudonymizované údaje. Pokud nevyjádříte souhlas, nebudete příjemcem obsahů a reklam přizpůsobených Vašim zájmům.",
              toggle: {
                value: "targeting",
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "",
                  col2: "",
                  col3: "",
                  col4: "",
                },
              ],
            },
            {
              title: "Více informací",
              description:
                'Máte-li jakékoli dotazy týkající se našich zásad týkajících se souborů cookie a vašich voleb, <a class="cc-link" target="_blank" href="https://www.pocernice.cz/urad/kontakty/">kontaktujte nás</a>.',
            },
          ],
        },
      },
    },
  });
});
